html, body{
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loginPage{
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: rgb(20, 20, 20);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header{
  background: rgb(240, 240, 240);
}

.title{
  display: inline-block;
  padding: 9px;
  font-weight: bold;
}

.container{
  padding: 40px;
}

.light{
  background: rgb(240, 240, 240);
}

.dark{
  color: white;
  background: rgb(40, 40, 40);
}

.insight{
  font-size: 32px;
}

.insight-bigger{
  font-size: 54px;
}

.insight-padding{
  padding: 10% 60px;
}

.insight-basic-padding{
  padding: 1% 60px;
}

.perfil{
  float: right;
}

.perfil .name{
  display: inline-block;
  padding: 9px;
}

.perfil .button{
  display: inline-block;
  padding: 9px;
  background: rgb(220, 220, 220);
}

.button-light{
  display: inline-block;
  padding: 15px;
  background: rgb(240, 240, 240);
  color: black;
  cursor: pointer;
  margin-right: 10px;
  width: 300px;
}

.sm {
  padding: 9px
}

.box{
  width: 300px;
  padding: 9px;
  background: rgb(240, 240, 240);
  display: inline-table;
  margin-right: 9px;
  margin-bottom: 9px;
}

.textInput{
  padding: 9px;
  border: 1px solid rgb(220, 220, 220);
  font-size: 12px;
  width: 100%;
}

.input{
  padding-bottom: 5px;
}

.label{
  padding-top: 5px;
  font-size: 14px;
}

.white{
  background: white;
}

.productItem{
  background: rgb(250, 250, 250);
  padding: 15px;
  margin-bottom: 15px;
  font-size: 18px;
}

.productItem .small{
  font-size: 14px;
  padding: 0 9px;
  margin: 0;
  font-weight: bold;
}

.simpleButton{
  font-size: 14px;
  padding: 3px 9px;
  margin: 9px;
  background-color: rgb(220, 220, 220);
  display: inline-block;
  font-weight: bold;
}

.productItem .title{
  font-size: 18px;
  padding: 0 9px;
  margin: 0;
  font-weight: bold;
}

.productItem .normal{
  padding: 0 9px;
  margin: 0;
  font-size: 16px;
}

.bigTitle{
  padding: 10%;
  color: white;
  background: rgb(20, 20, 20);
}

.heading{
  font-size: 42px;
}

.description{
  font-size: 32px;
}

.interactButton{
  font-size: 24px;
  border: 1px solid black;
  padding: 20px;
  font-size: 28px;
  cursor: pointer;
  margin: 10px;
}

.interactButton:hover{
  border: 1px solid rgb(70, 70, 70);
  background: rgb(240, 240, 240);
}

.division{
  padding: 5% 10%;
}